import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import ru from "./translations/ru.json";
import kg from "./translations/kg.json";
import en from './translations/en.json'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

const resources = {
   ru: {translation: ru},
   kg: {translation: kg},
   en: {translation: en}
};
const savedLanguage = localStorage.getItem("i18nextLng") || "en";
i18n
   .use(initReactI18next) // passes i18n down to react-i18next
   .init({
      resources,
      lng: savedLanguage, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option
      fallbackLng: "en",
      interpolation: {
         escapeValue: false, // react already safes from xss
      },
   });

export default i18n;
