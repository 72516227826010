import React from "react"

import cls from "./aboutUs.module.scss"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const AboutUs = () => {
  const { t } = useTranslation()
  const { footerData, aboutData } = useSelector((state) => state.mainSlice)
  return (
    <section className={cls.aboutUs} id="aboutUs">
      <div className="container">
        <h2 className="title">{t("aboutTitle")}</h2>
        <div className={cls.aboutUs__inner}>
          <p className={cls.aboutUs__text}>{aboutData.description}</p>
          <a
            className={cls.aboutUs__contact}
            target="_blank"
            rel="noreferrer"
            href={footerData.contact_us ?? "#"}
          >
            {t("contactUsBtn")}
          </a>
          <div className={cls.aboutUs__imgs}>
            {aboutData?.images?.map((img, index) => (
              <div key={index} className={cls.aboutUs__imgWrapper}>
                <img
                  src={img?.image}
                  alt={`About us ${img?.position}`}
                  className={cls.aboutUs__img}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs
