import React, { useEffect, useState } from "react"
import cls from "./banner.module.scss"
import Header from "../header/header"
import { useTranslation } from "react-i18next"
import { Link } from "react-scroll"
import anchor from "../../assets/banner/anchor.svg"
import { useSelector } from "react-redux"
import { ModalWindow } from "../modalWindow/modalWindow"

const Banner = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(false)

  const [scrollPosition, setScrollPosition] = useState(0)
  const [lookAnchor, setLookAnchor] = useState(false)
  const { activeBar } = useSelector((state) => state.mainSlice)

  const handleOpenModal = (value) => {
    setOpenModal(value)
  }

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY)
    }
    window.addEventListener("scroll", handleScroll)
    scrollPosition < 550 ? setLookAnchor(false) : setLookAnchor(true)
  }, [scrollPosition])

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden"
    }
  }, [openModal])

  return (
    <>
      <Header />
      <section className={cls.banner} id="/">
        <div className="container">
          <div className={cls.banner__inner}>
            <div className={cls.banner__text}>
              <h1 className={cls.main_title}>{t("mainTitle")}</h1>
              <p className={cls.subtitle}>{t("mainSubtitle")}</p>
              <button className={cls.btn_more} onClick={() => handleOpenModal(true)}>
                {t("moreBtn")}
              </button>
            </div>
          </div>
        </div>
      </section>
      {!activeBar && lookAnchor && (
        <div className={cls.anchor}>
          <Link to="service" spy={true} smooth={true} offset={-2500} duration={800}>
            <img src={anchor} alt="Scroll down" />
          </Link>
        </div>
      )}
      <ModalWindow handleOpenModal={handleOpenModal} isShow={openModal} />
    </>
  )
}

export default Banner
