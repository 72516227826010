import React from "react"
import { geekLogo } from "../../assets"
import HeaderNav from "../headerNav/headerNav"
import { Link } from "react-scroll"
import { useDispatch, useSelector } from "react-redux"
import { changeActiveBar } from "../../store/reducersRequest/mainSlice"
import classNames from "classnames"
import cls from "./header.module.scss"

const Header = () => {
  const dispatch = useDispatch()
  const { activeBar } = useSelector((state) => state.mainSlice)

  document.body.style.overflow = activeBar ? "hidden" : "visible"

  return (
    <header className={cls.header}>
      <div className="container">
        <div className={cls.header__inner}>
          <Link to="/" spy smooth duration={500} className={cls.header__logo}>
            <img src={geekLogo} alt="Logo" />
          </Link>
          <div className={cls.header__desktop}>
            <HeaderNav />
          </div>

          <div
            className={cls.hamburger}
            onClick={() => dispatch(changeActiveBar(!activeBar))}
          >
            <span
              className={classNames(cls.line, cls.line1, {
                [cls.active]: activeBar,
              })}
            />
            <span
              className={classNames(cls.line, cls.line2, {
                [cls.active]: activeBar,
              })}
            />
            <span
              className={classNames(cls.line, cls.line3, {
                [cls.active]: activeBar,
              })}
            />
          </div>
        </div>
      </div>
      <nav
        className={classNames(cls.menu, { [cls.active]: activeBar })}
        onClick={() => dispatch(changeActiveBar(!activeBar))}
      >
        <div className={cls.header__mobile}>
          <HeaderNav activeBar={activeBar} isMobile={true}/>
        </div>
      </nav>
    </header>
  )
}

export default Header
