import React, { useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { Navigation, Autoplay, Pagination, Mousewheel, Scrollbar } from "swiper"
import cls from "./ourTeam.module.scss"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { arrowRight } from "../../assets"

const OurTeam = () => {
  const { t } = useTranslation()
  const { teamsData = [] } = useSelector((state) => state.dataSlidersSlice)
  const [swiperInstance, setSwiperInstance] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    if (teamsData.length > 0) {
      setCurrentIndex(0)
    }
  }, [teamsData])

  const handleSlideChange = (swiper) => {
    if (!swiper) return
    setCurrentIndex(swiper.realIndex)
  }

  const handleNavigation = (direction) => {
    if (!swiperInstance) return

    if (direction === "prev") {
      swiperInstance.slidePrev(300)
    } else {
      swiperInstance.slideNext(300)
    }
  }

  const handleSwiperInit = (swiper) => {
    if (swiper) {
      setSwiperInstance(swiper)
      setCurrentIndex(0)
    }
  }

  if (!teamsData.length) {
    return null
  }

  return (
    <div className="container">
      <section className={cls.ourTeam}>
        <div className={cls.titlePagination}>
          <h2 className={cls.title} id="ourTeam">
            {t("ourTeamTitle")}
          </h2>
          <div className={cls.navigationWrapper}>
            <div
              className={cls.arrowLeft}
              onClick={() => handleNavigation("prev")}
            >
              <img
                src={arrowRight}
                alt="arrow left"
                style={{ transform: "rotate(180deg)" }}
              />
            </div>
            <div
              className={cls.arrowRight}
              onClick={() => handleNavigation("next")}
            >
              <img src={arrowRight} alt="arrow right" />
            </div>
          </div>
        </div>

        <div className={cls.swiperWrapper}>
          <Swiper
            grabCursor={true}
            modules={[Navigation, Autoplay, Pagination, Mousewheel, Scrollbar]}
            loop={true}
            slidesPerView={4}
            spaceBetween={20}
            speed={500}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            mousewheel={{
              forceToAxis: true,
              sensitivity: 1,
            }}
            freeMode={true}
            onSwiper={handleSwiperInit}
            onSlideChange={handleSlideChange}
            breakpoints={{
              200: { slidesPerView: 1, spaceBetween: 20 },
              400: { slidesPerView: 1.2, spaceBetween: 20 },
              550: { slidesPerView: 1.5, spaceBetween: 20 },
              728: { slidesPerView: 2, spaceBetween: 20 },
              960: { slidesPerView: 3, spaceBetween: 20 },
              1250: { slidesPerView: 4, spaceBetween: 20 },
            }}
            className={cls.swiperContainer}
            loopAdditionalSlides={1}
            normalizeSlideIndex={true}
            watchSlidesProgress={true}
          >
            {teamsData.map((member, index) => (
              <SwiperSlide key={member.id || index}>
                <div
                  className={`${cls.teamCard} ${
                    currentIndex === index ? cls.active : ""
                  }`}
                  style={{
                    backgroundImage: `url('${member.avatar}')`,
                  }}
                >
                  <div className={cls.memberInfo}>
                    <h3 className={cls.memberName}>{member.full_name}</h3>
                    <p className={cls.memberPosition}>{member.position}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </div>
  )
}

export default OurTeam
