import React from "react"
import cls from "./ourService.module.scss"
import ServiceCard from "../serviceCard/serviceCard"
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const blockAnimation = {
  hidden: {
    y: 100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 1,
    opacity: 1,
    transition: {
      duration: 0.7,
      delay: custom * 0.3,
      ease: "easeOut",
    },
  }),
}

const OurService = () => {
  const { t } = useTranslation()
  const { ourServiceData } = useSelector((state) => state.mainSlice)
  if (!ourServiceData.length) {
    return null
  }
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2, once: 1 }}
      className={cls.our_service}
      id="service"
    >
      <div className="container">
        <motion.h2 variants={blockAnimation} custom={1} className="title">
          {t("serviceTitle")}
        </motion.h2>
        <motion.div
          variants={blockAnimation}
          custom={2}
          className={cls.service_box}
        >
          {ourServiceData?.map((service, ind) => (
            <ServiceCard key={service.position} service={service} />
          ))}
        </motion.div>
      </div>
    </motion.section>
  )
}

export default OurService
