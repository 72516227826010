import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  changeCommentsData,
  changePortfolio,
  changeTeamsData,
} from "./dataSlidersSlice"
import { standartAxios } from "../../common/helpers"

const getInitialLanguage = () => {
  if (typeof window !== "undefined") {
    const savedLang =
      localStorage.getItem("lang") || localStorage.getItem("i18nextLng")
    if (savedLang === "kg") return "ky"

    return savedLang || "en"
  }
  return "en"
}

const initialState = {
  language: getInitialLanguage(),
  technologyData: [],
  aboutData: {},
  ourServiceData: [],
  footerData: {},
  activeBar: false,
}

export const toTakeData = createAsyncThunk(
  "toTakeData",
  async (arg, { dispatch }) => {
    try {
      const { data } = await standartAxios(arg.api, arg.language)
      switch (arg.api) {
        case "portfolio":
          dispatch(changePortfolio(data))
          break
        case "services":
          dispatch(changeOurServiceData(data))
          break
        case "reviews":
          dispatch(changeCommentsData(data))
          break
        case "about":
          dispatch(changeAboutData(data))
          break
        case "staff":
          dispatch(changeTeamsData(data))
          break
        case "footer":
          dispatch(changeFooterData(data))
          break
      }
    } catch (err) {
      if (arg.language === "ru") {
        alert("ошибка соединения")
      } else if (arg.language === "en") {
        alert("Connection error")
      } else if (arg.language === "ky") {
        alert("Туташуу катасы")
      }
    }
  }
)

const mainSlice = createSlice({
  name: "mainSlice",
  initialState,
  reducers: {
    changeLanguage: (state, action) => {
      state.language = action.payload
    },
    changeTechnologyData: (state, action) => {
      state.technologyData = action.payload
    },
    changeOurServiceData: (state, action) => {
      state.ourServiceData = action.payload
    },
    changeFooterData: (state, action) => {
      state.footerData = action.payload
    },
    changeActiveBar: (state, action) => {
      state.activeBar = action.payload
    },
    changeAboutData: (state, action) => {
      state.aboutData = action.payload;
    }
  },
})

export const {
  changeLanguage,
  changeTechnologyData,
  changeOurServiceData,
  changeFooterData,
  changeActiveBar,
  changeAboutData
} = mainSlice.actions

export default mainSlice.reducer
