import React from "react"
import { motion } from "framer-motion"
import cls from "./stackTech.module.scss"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const blockAnimation = {
  hidden: {
    y: 100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 1,
    opacity: 1,
    transition: {
      duration: 0.7,
      delay: custom * 0.3,
      ease: "easeOut",
    },
  }),
}

const StackTech = () => {
  const { t } = useTranslation()
  const { technologyData } = useSelector((state) => state.mainSlice)

  const filteredCategories = technologyData.filter(
    (category) => category.stack && category.stack.length > 0
  )
  return (
    <div className="container">
      <div className={cls.stack_tech}>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.2, once: true }}
        >
          <h2 className="title">{t("stackTitle")}</h2>
          <p className={cls.stack_description}>{t("stackDescription")}</p>
          <div className={cls.categories_wrapper}>
            <div className={cls.categories_row}>
              {filteredCategories.map((category, categoryIndex) => (
                <motion.div
                  key={category.id}
                  custom={categoryIndex + 1}
                  variants={blockAnimation}
                  className={cls.category_section}
                >
                  <h3 className={cls.category_title}>{category.title}</h3>
                  <div className={cls.tech_scroll_container}>
                    <div className={cls.tech_grid}>
                      {category.stack?.map((tech, techIndex) => (
                        <div
                          key={tech.title + techIndex}
                          className={cls.tech_item}
                        >
                          <div className={cls.tech_icon}>
                            <img src={tech.icon} alt={tech.title} />
                          </div>
                          <div className={cls.tech_name}>{tech.title}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default StackTech