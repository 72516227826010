import React, { useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"
import { Navigation, Autoplay, Mousewheel, Scrollbar } from "swiper"
import cls from "./portfolio.module.scss"
import { useTranslation } from "react-i18next"
import appStore from "../../assets/icon/appStore.svg"
import googlePlay from "../../assets/icon/googlePlay.svg"
import classNames from "classnames"
import { useSelector } from "react-redux"

const Portfolio = () => {
  const { t } = useTranslation()
  const { portfolioData: companies = [] } = useSelector(
    (state) => state.dataSlidersSlice
  )
  const [activeCompany, setActiveCompany] = useState(null)
  const [swiperInstance, setSwiperInstance] = useState(null)
  const [autoplayEnabled, setAutoplayEnabled] = useState(true)
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    if (companies && companies.length > 0) {
      setActiveCompany(companies[0])
    }
  }, [companies])

  const handleSlideChange = (swiper) => {
    if (!swiper) return
    const index = swiper.realIndex
    setCurrentIndex(index)

    if (companies && companies[index]) {
      setActiveCompany(companies[index])
    }
  }

  const handleSlideClick = (company, index) => {
    if (currentIndex === index) return
    setActiveCompany(company)
    setCurrentIndex(index)
    if (swiperInstance) {
      try {
        if (swiperInstance.autoplay && swiperInstance.autoplay.running) {
          swiperInstance.autoplay.stop()
        }
        if (swiperInstance.slideToLoop) {
          swiperInstance.slideToLoop(index)
        } else {
          swiperInstance.slideTo(index)
        }
        setTimeout(() => {
          if (
            swiperInstance &&
            swiperInstance.autoplay &&
            !swiperInstance.autoplay.running &&
            autoplayEnabled
          ) {
            swiperInstance.autoplay.start()
          }
        }, 7000)
      } catch (error) {
        console.error("Ошибка при переключении слайда:", error)
      }
    }
  }

  useEffect(() => {
    if (swiperInstance && swiperInstance.autoplay) {
      try {
        if (autoplayEnabled) {
          swiperInstance.autoplay.start()
        } else {
          swiperInstance.autoplay.stop()
        }
      } catch (error) {
        console.error("Ошибка при управлении автопрокруткой:", error)
      }
    }
  }, [autoplayEnabled, swiperInstance])

  if (!companies || companies.length === 0) {
    return null
  }

  const handleSwiperInit = (swiper) => {
    if (swiper) {
      setSwiperInstance(swiper)

      if (companies && companies.length > 0) {
        const initialIndex = 0
        setCurrentIndex(initialIndex)
        setActiveCompany(companies[initialIndex])
      }
    }
  }

  return (
    <div className="container" id="portfolio">
      <div className={cls.swiperSwiper}>
        <h2 className="title">{t("portfolio")}</h2>
        <div className={cls.swiper}>
          {activeCompany && (
            <div className={cls.infoBlock}>
              <div className={cls.infoWrapper}>
                <div className={cls.imageContainer}>
                  <img
                    src={activeCompany.icon}
                    alt={activeCompany.title}
                    className={cls.companyImage}
                  />
                </div>
                <div className={cls.contentContainer}>
                  <h2 className={cls.companyTitle}>{activeCompany.title}</h2>
                  <p className={cls.companyDescription}>
                    {activeCompany.description}
                  </p>
                  <div className={cls.btns}>
                    <a
                      className={classNames(cls.btn, cls.watchProject)}
                      href={activeCompany.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("watchProject")}
                    </a>
                    <div className={cls.btnApps}>
                      {activeCompany.app_store_link && (
                        <a
                          className={cls.btn}
                          href={activeCompany.app_store_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={appStore} alt="logo App Store" />
                        </a>
                      )}
                      {activeCompany.google_play_link && (
                        <a
                          className={cls.btn}
                          href={activeCompany.google_play_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={googlePlay} alt="logo GooglePlay" />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={cls.carouselContainer}>
            <Swiper
              modules={[Navigation, Autoplay, Mousewheel, Scrollbar]}
              navigation={true}
              loop={true}
              slidesPerView={2}
              spaceBetween={10}
              speed={500}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              mousewheel={{
                forceToAxis: true,
                sensitivity: 5,
              }}
              freeMode={true}
              onSwiper={handleSwiperInit}
              onSlideChange={handleSlideChange}
              breakpoints={{
                480: { slidesPerView: 2, spaceBetween: 1 },
                640: { slidesPerView: 3, spaceBetween: 1 },
                768: { slidesPerView: 4, spaceBetween: 1 },
                1024: { slidesPerView: 6, spaceBetween: 1 },
              }}
              className={cls.swiperContainer}
            >
              {companies.map((company, index) => (
                <SwiperSlide key={company.id || index}>
                  <div
                    className={`${cls.logoItem} ${
                      currentIndex === index ? cls.logoActive : ""
                    }`}
                    onClick={() => handleSlideClick(company, index)}
                  >
                    <img
                      src={company.icon || ""}
                      alt={company.title || ""}
                      className={cls.logoImage}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Portfolio
