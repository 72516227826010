import React from "react"
import styles from "./footerSocials.module.scss"

const FooterSocials = ({ socialMedias }) => {
  if (!socialMedias?.length) return null

  return (
    <div className={styles.socials}>
      {socialMedias.map((i, ind) => (
        <a key={ind} target="_blank" href={i?.url} rel="noreferrer">
          <img src={i?.icon} alt="social-icon" />
        </a>
      ))}
    </div>
  )
}

export default FooterSocials
