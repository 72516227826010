import React, { useState, useRef } from "react"
import { language } from "../../assets"
import { Link } from "react-scroll"
import { useTranslation } from "react-i18next"
import i18n from "../../i18n"
import { AnimatePresence, motion } from "framer-motion"
import { useDispatch, useSelector } from "react-redux"
import {
  changeActiveBar,
  changeLanguage,
} from "../../store/reducersRequest/mainSlice"
import { pages } from "../../StackTech"
import cls from "./headerNav.module.scss"
import classNames from "classnames"

const translateName = ["ru", "kg", "en"]

const languageNames = {
  ru: "Русский",
  en: "Английский",
  kg: "Кыргызский",
}

const HeaderNav = ({ activeBar, isMobile = false }) => {
  const dispatch = useDispatch()
  const [visiblePopup, setVisiblePopup] = useState(false)
  const translateRef = useRef()
  const { t } = useTranslation()
  const { portfolioData } = useSelector((state) => state.dataSlidersSlice)
  const { commentsData } = useSelector((state) => state.dataSlidersSlice)
  const { footerData } = useSelector((state) => state.mainSlice)

  const filteredPages = pages.filter((link) => {
    if (link.to === "portfolio" && portfolioData.length === 0) return false
    if (link.to === "comments" && commentsData.length === 0) return false
    return true
  })

  const changeLang = (e, lang) => {
    i18n.changeLanguage(e.target.getAttribute("value"))
    const newLang = lang === "kg" ? "ky" : lang
    localStorage.setItem("i18nextLng", lang)
    localStorage.setItem("lang", newLang)
    dispatch(changeLanguage(newLang))
  }

  const toggleVisiblePopup = () => {
    setVisiblePopup(!visiblePopup)
  }

  return (
    <>
      <div
        className={classNames(cls.header__info, {
          [cls.isHamburger]: activeBar,
        })}
      >
        <nav
          className={classNames(cls.nav, {
            [cls.isHamburger]: activeBar,
            [cls.isMobile]: !isMobile,
          })}
        >
          {filteredPages?.map((link) => (
            <Link
              key={link.id}
              to={link?.to}
              onClick={() => dispatch(changeActiveBar(false))}
              spy={true}
              smooth={true}
              offset={link?.offset}
              duration={500}
              className={classNames(cls.nav__link, {
                [cls.nav__link_en]: i18n.language === "en",
                [cls.nav__link_kg]: i18n.language === "kg",
                [cls.isHamburger]: activeBar,
              })}
            >
              {t(link?.text)}
            </Link>
          ))}
        </nav>
        <div
          ref={translateRef}
          className={classNames(cls.translate, {
            [cls.active]: visiblePopup,
            [cls.isHamburger]: activeBar,
          })}
          onClick={toggleVisiblePopup}
        >
          <motion.img
            src={language}
            alt="language"
            animate={{ rotate: visiblePopup ? 180 : 0 }}
            transition={{ duration: 0.3, delay: 0.1, ease: "easeOut" }}
          />

          <div style={{ textTransform: "uppercase" }}>{i18n.language}</div>
          <AnimatePresence>
            {visiblePopup && (
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: "auto" }}
                exit={{ height: 0 }}
                transition={{ duration: 0.4, ease: "easeOut" }}
                style={{ overflow: "hidden" }}
                className={cls.translate__popup}
              >
                {translateName
                  .filter((lang) => i18n.language !== lang)
                  .map((lang, index) => (
                    <div
                      key={index}
                      className={cls.translate__item}
                      value={lang}
                      onClick={(e) => changeLang(e, lang)}
                    >
                      {languageNames[lang]}
                    </div>
                  ))}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <a
        className={classNames(cls.header__contact, {
          [cls.isHamburger]: !activeBar,
          [cls.isMobile]: isMobile,
        })}
        target="_blank"
        rel="noreferrer"
        href={footerData.contact_us ?? "#"}
      >
        {t("contactUsBtn")}
      </a>
    </>
  )
}

export default HeaderNav
