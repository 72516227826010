import React from "react"
import { geekLogo } from "../../assets"

import cls from "./footer.module.scss"
import { useTranslation } from "react-i18next"
import phoneIcon from "../../assets/footer/footer_phone.svg"
import emailIcon from "../../assets/footer/footer_email.svg"
import addressIcon from "../../assets/footer/footer_address.svg"
import { useSelector } from "react-redux"
import FooterSocials from "../footerSocials/footerSocials"
import useMediaQuery from "../../helpers"

const Footer = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery("(max-width:728px)")
  const { footerData } = useSelector((state) => state.mainSlice)

  return (
    <footer className={cls.footer} id="footer">
      <div className="container">
        <div className={cls.footer__inner}>
          <div className={cls.footer__text}>
            <div className={cls.footer__logo}>
              <img src={geekLogo} alt="Geek Logo" />
            </div>
            {isMobile && (
              <FooterSocials socialMedias={footerData?.sosial_medias} />
            )}
          </div>
          {!isMobile && (
            <FooterSocials socialMedias={footerData?.sosial_medias} />
          )}
          <div className={cls.contacts}>
            <div className={cls.contact}>
              <div className={cls.contactItem}>
                <img className={cls.contactIcon} src={emailIcon} alt="email" />
                <a
                  href={`mailto:${footerData.email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {footerData.email}
                </a>
              </div>
            </div>
            <div className={cls.contact}>
              {footerData.addresses?.map((item, ind) => (
                <div key={ind} className={cls.contactItem}>
                  <img
                    className={cls.contactIcon}
                    src={addressIcon}
                    alt="address"
                  />
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.address}
                  </a>
                </div>
              ))}
            </div>
            <div className={cls.contact}>
              {footerData.phone_numbers?.map((num, ind) => (
                <div key={ind} className={cls.contactItem}>
                  <img
                    className={cls.contactIcon}
                    src={phoneIcon}
                    alt="phone"
                  />
                  <a href={`tel:${num}`}>{num}</a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
