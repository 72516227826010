import React from "react"

import cls from "./serviceCard.module.scss"

const ServiceCard = ({ service }) => {
  return (
    <div className={cls.service_card}>
      <div className={cls.service_card__img}>
        <img src={service?.icon} alt="icon" />
      </div>
      <h3 className={cls.service_card__title}>{service?.title}</h3>
      <p className={cls.service_card__subtitle}>{service?.description}</p>
    </div>
  )
}

export default ServiceCard
